import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getGiftCardOrder } from "../../../../services/concessions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { decryptData } from "../../../../utils/helper";
import { addGiftCart, giftCardOrderSuccess } from "../../../../store/giftshop/action";
import { findCinemaObject } from "../../../../utils/formatter";
import { setCinemaAction } from "../../../../store/application/action";
import { IPosGiftCards } from "../../../../models/pos";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import { setModal, toggleTicketModal } from "../../../../store/ticketBooking/action";
import { ROUTES } from "../../../../constants/path";
import { toast } from "react-toastify";

const PaymentLink: FC<any> = (() => {

    const { countryId, cinemas } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            cinemas: state.applicationReducer.cinemas
        })
    );

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cinemaSlug, setCinemaSlug] = useState('');

    const setLocation = (location: string) => {
        let currentCinema = findCinemaObject(cinemas, location);
        dispatch(setCinemaAction(currentCinema));
    };

    useEffect(() => {
        if (cinemas && cinemas.length > 0 && cinemaSlug) {
            setLocation(cinemaSlug);
        }
    }, [cinemas, cinemaSlug])

    const fetchDetails = async (code: string) => {
        const saleId = decryptData(decodeURIComponent(code), 'reading');
        const { data } = await getGiftCardOrder({
            countryId,
            userSessionId: saleId
        })
        if (data && data.statusCode === 200 && data.data) {
            if(data.data.step == 4){
                toast.error('Order already completed');
                navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`)
                return;
            }
            setCinemaSlug(data.cinemaId);

            if (data.data && data.data.giftcards && Array.isArray(data.data.giftcards)) {
                const cartData = data.data.giftcards.map((v: IPosGiftCards) => {
                    const egiftCardImage = v.image?.split("/");
                    return {
                        address1: v.delivery_add_1,
                        address2: v.delivery_add_2,
                        cardId: v.id,
                        carddesign: v.carddesign,
                        city: v.city,
                        confirmRecipientEmail: v.confirmemail,
                        deliveryDate: v.deliverydate,
                        description: v.description,
                        eGiftcardDesign: v.eGiftcardDesign,
                        giftMessage: v.giftMessage,
                        giftamount: v.promoType?0:v.giftamount,
                        giftcardType: v.giftcard_type,
                        id: v.id,
                        image: v.image,
                        imgId: egiftCardImage ? egiftCardImage[egiftCardImage.length - 1] : 0,
                        isVariablePriceItem: v.IsVariablePriceItem,
                        itemTotal: v.itemTotal,
                        maximumVariablePriceInCents: v.MinimumVariablePriceInCents,
                        minimumVariablePriceInCents: v.MaximumVariablePriceInCents,
                        parentIndex: v.parentIndex ? v.parentIndex : undefined,
                        phone: v.phone,
                        postCode: v.postcode,
                        price: v.promoType?0:v.price,
                        promo: v.promo,
                        promoType: v.promoType,
                        purchaserEmail: v.purchaserEmail,
                        quantity: v.quantity,
                        recipientName: v.recipientname,
                        recipientemail: v.recipientemail,
                        senderName: v.yourname,
                        state: v.state,
                        tax: v.tax,
                        type: v.type,
                        paymentType: "POS"
                    }
                })
                dispatch(addGiftCart(cartData));
                dispatch(giftCardOrderSuccess({ cinemaId: data.cinemaId, countryId: data.countryId, userSessionId: saleId }));
                const modal =
                    TICKET_FLOW[TICKET_FLOW_TYPES.GIFTSHOP][MODAL_TYPE.GIFTSHOP_PAYMENT];
                dispatch(
                    setModal({
                        ...modal,
                        type: TICKET_FLOW_TYPES.GIFTSHOP,
                        data: 'pos'
                    })
                );
                dispatch(toggleTicketModal(true));
                navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`)
            }

        }
    }

    useEffect(() => {
        if (id && countryId) {
            fetchDetails(id)
        }
    }, [id, countryId])
    return null;
})

export default PaymentLink;